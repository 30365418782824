import React, {
    useState,
    useRef,
    useEffect,
    useImperativeHandle,
    useCallback,
    forwardRef,
} from "react"
import uuid from "react-uuid"
import PartView3DFC from "./editor/PartView3D/PartView3DFC"
import {
    Circle,
    Polygon,
    Point3d,
    Mask,
    Square,
    min_rect_size,
    updateMaskPointDelta,
    updateCircleDelta,
    updatePointDelta,
    updateSquarePointDelta,
    updatePolygonData,
} from "./editor/Geometries"
import { HMCom } from "./io/HMCom"
import { HMFileIO } from "./io/HMFileIO"
import { buildPictureFromSVG, buildSVGFile } from "./editor/SVGFileWriter"
import { ComposerContext } from "./Contexts"
import { DEFAULT_FILL_COLOR } from "./editor/SVGRenderer/Constants"
import { removeClassList, addClassList } from "./common/utils"
import { toast } from "react-toastify"

const DEBUG = false
const INCREMENTAL_SAVE = false
const FORCE_2D_MODE = true

const ComposerFC = forwardRef(
    (
        {
            dirId,
            picture,
            picturesLength,
            onChangePic,
            currentIndex,
            updatePictureState,
            updatePicGeoCompo,
            copyLink,
            copyFile,
            handleMenu,
        },
        ref
    ) => {
        const viewerRef = useRef()
        const hmComRef = useRef(HMCom())
        const [ready, setReady] = useState(false)
        const [data, setData] = useState(null)
        const [geomId, setGeomId] = useState("")
        const [compoEdited, setCompoEdited] = useState(false)
        const [currentEditMode, setCurrentEditMode] = useState("square")
        const [newObjects, setNewObjects] = useState([])
        const [loader, setLoader] = useState(true)
        const [stateData, setStateData] = useState({
            points: [],
            polygons: [],
            circles: [],
            masks: [],
            squares: [],
        })
        const [menuState, setMenuState] = useState({
            viewModes: { magnet: false, debug: false, displayEditForm: true },
            editModes: {
                selection: false,
                crop: false,
                point: false,
                polygon: false,
                circle: false,
                circle3p: false,
                mask: false,
                square: true,
            },
            mode3D: false,
        })

        const updateMenu = useCallback(
            (menuStates) => {
                DEBUG && console.log(`Composer: UpdateMenu called`)
                const { mode3D, viewModes, editModes } = menuStates
                // Hack the menu bar to display only needed actions
                if (mode3D) {
                    addClassList("menu_composer_crop")
                    addClassList("menu_composer_selection")
                    removeClassList("menu_composer_debug")
                    removeClassList("menu_composer_magnet")
                    /*
                     * removeClassList("menu_composer_3d_cube")
                     * addClassList("menu_composer_3d_square")
                     */
                } else {
                    // 2D Mode
                    addClassList("menu_composer_debug")
                    addClassList("menu_composer_magnet")
                    if (picture.src.endsWith("svg")) {
                        removeClassList("menu_composer_crop")
                        removeClassList("menu_composer_selection")
                    } else {
                        addClassList("menu_composer_crop")
                        addClassList("menu_composer_selection")
                    }
                    /*
                     * addClassList("menu_composer_3d_cube")
                     * removeClassList("menu_composer_3d_square")
                     */
                }
                viewModes.displayEditForm
                    ? document
                          .getElementById("menu_composer_list")
                          .firstChild.firstChild.classList.add("selected")
                    : document
                          .getElementById("menu_composer_list")
                          .firstChild.firstChild.classList.remove("selected")
                viewModes.magnet
                    ? document
                          .getElementById("menu_composer_magnet")
                          .firstChild.firstChild.classList.add("selected")
                    : document
                          .getElementById("menu_composer_magnet")
                          .firstChild.firstChild.classList.remove("selected")
                viewModes.debug
                    ? document
                          .getElementById("menu_composer_debug")
                          .firstChild.firstChild.classList.add("selected")
                    : document
                          .getElementById("menu_composer_debug")
                          .firstChild.firstChild.classList.remove("selected")
                editModes.selection
                    ? document
                          .getElementById("menu_composer_selection")
                          .firstChild.firstChild.classList.add("selected")
                    : document
                          .getElementById("menu_composer_selection")
                          .firstChild.firstChild.classList.remove("selected")
                editModes.crop
                    ? document
                          .getElementById("menu_composer_crop")
                          .firstChild.firstChild.classList.add("selected")
                    : document
                          .getElementById("menu_composer_crop")
                          .firstChild.firstChild.classList.remove("selected")
            },
            [picture]
        )

        /**
         * turns off all editmodes
         */
        const unSelectAllEditModes = useCallback(() => {
            let menuStates = {
                ...menuState,
                editModes: {
                    selection: false,
                    crop: false,
                    point: false,
                    polygon: false,
                    circle: false,
                    circle3p: false,
                    mask: false,
                    square: false,
                },
            }
            setMenuState(menuStates)
            updateMenu(menuStates)
        }, [menuState, updateMenu])

        /**
         * event handler declared as arrow function for binding
         */
        const addPoint = useCallback(
            async (point3d) => {
                const point = new Point3d({
                    id: uuid(),
                    name: `point${stateData.points.length + 1}`,
                    points: point3d,
                    label_size: 48,
                })
                const points = [...stateData.points, point]
                let state = {
                    ...stateData,
                    points: points,
                }
                setLoader(INCREMENTAL_SAVE)
                setStateData(state)
                DEBUG &&
                    console.log(
                        `add a point [${JSON.stringify(point)}]; ${points.length} points in the list`
                    )
                if (INCREMENTAL_SAVE) {
                    try {
                        let id = await hmComRef.current.sendPoint(point)
                        updateId("points", point.id, id)
                    } finally {
                        setLoader(false)
                    }
                }
                setCompoEdited(true)
                setNewObjects([...new Set([...newObjects, point.id])])
                return points.length
            },
            [stateData, newObjects]
        )

        /**
         * event handler declared as arrow function for binding
         */
        const addPolygon = useCallback(
            async (pointList) => {
                let polygonName = `polygon${stateData.polygons.length + 1}`
                const points = pointList.map(
                    (pt) =>
                        new Point3d({
                            id: uuid(),
                            name: polygonName + " point",
                            points: pt,
                        })
                )
                const polygon = new Polygon({
                    id: uuid(),
                    name: polygonName,
                    points: points,
                    stroke_width: 5,
                    bg_color: DEFAULT_FILL_COLOR,
                })
                const polygons = [...stateData.polygons, polygon]
                let state = {
                    ...stateData,
                    polygons: polygons,
                }
                DEBUG && console.log("polygon object : ", polygon)
                setStateData(state)
                setLoader(INCREMENTAL_SAVE)
                DEBUG &&
                    console.log(
                        `add a polygon [${polygon.id}]; ${polygons.length} polygons in the list`
                    )
                if (INCREMENTAL_SAVE) {
                    try {
                        let id = await hmComRef.current.sendPolygon(polygon)
                        updateId("polygons", polygon.id, id)
                    } finally {
                        setLoader(false)
                    }
                }
                setCompoEdited(true)
                setNewObjects([...new Set([...newObjects, polygon.id])])
                return polygons.length
            },
            [stateData, newObjects]
        )

        /**
         * Add a new Mask in mask list and return it
         */
        const addMask = useCallback(
            async (pointList) => {
                let maskName = `mask${stateData.masks.length + 1}`
                const points = pointList.map(
                    (pt) =>
                        new Point3d({
                            id: uuid(),
                            name: maskName + " point",
                            points: pt,
                        })
                )
                const mask = new Mask({
                    id: uuid(),
                    name: maskName,
                    points: points,
                })
                const masks = [...stateData.masks, mask]
                let state = {
                    ...stateData,
                    masks: masks,
                }
                DEBUG && console.log("mask object : ", mask)
                setLoader(INCREMENTAL_SAVE)
                setStateData(state)
                DEBUG &&
                    console.log(
                        `add a mask [${mask.id}]; ${masks.length} masks in the list`
                    )
                if (INCREMENTAL_SAVE) {
                    try {
                        let id = await hmComRef.current.sendMask(mask)
                        updateId("masks", mask.id, id)
                    } finally {
                        setLoader(INCREMENTAL_SAVE)
                    }
                }
                setCompoEdited(true)
                setNewObjects([...new Set([...newObjects, mask.id])])
                return mask
            },
            [stateData, newObjects]
        )

        /**
         * Add a new Square in square list and return it
         */
        const addSquare = useCallback(
            async (pointList) => {
                if (
                    Math.abs(pointList[0][0] - pointList[1][0]) < min_rect_size
                ) {
                    pointList[1][0] = pointList[0][0] + min_rect_size
                }
                if (
                    Math.abs(pointList[0][1] - pointList[1][1]) < min_rect_size
                ) {
                    pointList[1][1] = pointList[0][1] + min_rect_size
                }
                let squareName = `rect${stateData.squares.length + 1}`
                const points = pointList.map(
                    (pt) =>
                        new Point3d({
                            id: uuid(),
                            name: squareName + " point",
                            points: pt,
                            label_size: 48,
                        })
                )
                const square = new Square({
                    id: uuid(),
                    name: squareName,
                    points: points,
                    bg_color: DEFAULT_FILL_COLOR,
                    label_size: 48,
                    stroke_width: 5,
                })
                const squares = [...stateData.squares, square]
                let state = {
                    ...stateData,
                    squares: squares,
                }
                setLoader(INCREMENTAL_SAVE)
                setStateData(state)
                DEBUG &&
                    console.log(
                        `add a square [${square.id}]; ${squares.length} masks in the list`
                    )
                if (INCREMENTAL_SAVE) {
                    try {
                        let id = await hmComRef.current.sendSquare(square)
                        updateId("squares", square.id, id)
                    } finally {
                        setLoader(INCREMENTAL_SAVE)
                    }
                }
                setCompoEdited(true)
                setNewObjects([...new Set([...newObjects, square.id])])
                return square
            },
            [stateData, newObjects]
        )

        /**
         * @param {any[]} circleData [center as point, radius, normal, ptOnCircle as point]
         * @returns {Promise<number>} number of current circles after adding a circle
         */
        const addCircle = useCallback(
            async (circleData) => {
                let circleName = `circle${stateData.circles.length + 1}`
                const [center, radius, normal, ptOnCircle] = circleData
                let point_constructor = {
                    id: uuid(),
                    name: circleName + " center",
                    points: center,
                }
                const centerPt = new Point3d(point_constructor)
                const circlePt = ptOnCircle
                    ? new Point3d({
                          id: uuid(),
                          name: circleName + " ptOnCircle",
                          points: ptOnCircle,
                      })
                    : undefined
                let circle_constructor = {
                    id: uuid(),
                    name: circleName,
                    center: centerPt,
                    radius: radius,
                    normal: normal ?? [0, 0, 1],
                    ptOnCircle: circlePt,
                    bg_color: DEFAULT_FILL_COLOR,
                    label_size: 48,
                    stroke_width: 5,
                }
                const circle = new Circle(circle_constructor)
                const circles = [...stateData.circles, circle]
                let state = {
                    ...stateData,
                    circles,
                }
                setLoader(INCREMENTAL_SAVE)
                setStateData(state)
                if (INCREMENTAL_SAVE) {
                    try {
                        let id = await hmComRef.current.sendCircle(circle)
                        updateId("circles", circle.id, id)
                    } finally {
                        setLoader(false)
                    }
                }
                setCompoEdited(true)
                viewerRef.current?.setCompoEdited(true)
                setNewObjects([...new Set([...newObjects, circle.id])])
                return circles.length
            },
            [stateData, newObjects]
        )

        /**
         * Add a Hole in an existing mask
         */
        const addHole = useCallback(
            async (maskId, pointList) => {
                const mask = stateData.masks.find((m) => m.id === maskId)
                const points = pointList.map(
                    (pt) =>
                        new Point3d({
                            id: uuid(),
                            name: mask.name + " hole point",
                            points: pt,
                        })
                )
                const newMask = { ...mask, holes: mask.holes.concat(points) }
                let state = {
                    ...stateData,
                    masks: stateData.masks.map((el) =>
                        el.id === maskId ? newMask : el
                    ),
                }
                setLoader(INCREMENTAL_SAVE)
                setStateData(state)
                DEBUG && console.log(`add a hole in [${maskId}]`)
                if (INCREMENTAL_SAVE) {
                    try {
                        await hmComRef.current.updateMaskAddHole(
                            maskId,
                            pointList
                        )
                    } finally {
                        setLoader(false)
                    }
                }
                setCompoEdited(true)
                setNewObjects([...new Set([...newObjects, maskId])])
                return mask
            },
            [stateData, newObjects]
        )

        const duplicateGeometries = (object_ids) => {
            // Nested deep copy
            let all_objects = JSON.parse(JSON.stringify(stateData))
            let points_to_copy = all_objects.points.filter((pt) =>
                object_ids.includes(pt.id)
            )
            let polygons_to_copy = all_objects.polygons.filter((po) =>
                object_ids.includes(po.id)
            )
            let masks_to_copy = all_objects.masks.filter((m) =>
                object_ids.includes(m.id)
            )
            let circles_to_copy = all_objects.circles.filter((c) =>
                object_ids.includes(c.id)
            )
            let squares_to_copy = all_objects.squares.filter((sq) =>
                object_ids.includes(sq.id)
            )
            let geomLength = 0
            for (let i = 0; i < points_to_copy.length; i++) {
                geomLength++
                points_to_copy[i].id = uuid()
                points_to_copy[i].name += "_copy"
            }
            for (let i = 0; i < polygons_to_copy.length; i++) {
                geomLength++
                polygons_to_copy[i].id = uuid()
                polygons_to_copy[i].name += "_copy"
                for (let j = 0; j < polygons_to_copy[i].points.length; j++) {
                    polygons_to_copy[i].points[j].id = uuid()
                }
            }
            for (let i = 0; i < masks_to_copy.length; i++) {
                geomLength++
                masks_to_copy[i].id = uuid()
                masks_to_copy[i].name += "_copy"
                for (let j = 0; j < masks_to_copy[i].points.length; j++) {
                    masks_to_copy[i].points[j].id = uuid()
                }
                for (let k = 0; k < masks_to_copy[i].holes.length; k++) {
                    masks_to_copy[i].holes[k].id = uuid()
                }
            }
            for (let i = 0; i < circles_to_copy.length; i++) {
                geomLength++
                circles_to_copy[i].id = uuid()
                circles_to_copy[i].name += "_copy"
                circles_to_copy[i].center.id = uuid()
                circles_to_copy[i].ptOnCircle.id = uuid()
            }
            for (let i = 0; i < squares_to_copy.length; i++) {
                geomLength++
                squares_to_copy[i].id = uuid()
                squares_to_copy[i].name += "_copy"
                for (let j = 0; j < squares_to_copy[i].points.length; j++) {
                    squares_to_copy[i].points[j].id = uuid()
                }
            }
            const _points = [...stateData.points, ...points_to_copy]
            const _masks = [...stateData.masks, ...masks_to_copy]
            const _polygons = [...stateData.polygons, ...polygons_to_copy]
            const _circles = [...stateData.circles, ...circles_to_copy]
            const _squares = [...stateData.squares, ...squares_to_copy]
            let ids = [
                ...points_to_copy.map((p) => p.id),
                ...masks_to_copy.map((m) => m.id),
                ...polygons_to_copy.map((p) => p.id),
                ...circles_to_copy.map((c) => c.id),
                ...squares_to_copy.map((s) => s.id),
            ]
            setNewObjects([...new Set([...newObjects, ...ids])])
            viewerRef.current?.setCompoEdited(true)
            let state = {
                points: _points,
                masks: _masks,
                polygons: _polygons,
                circles: _circles,
                squares: _squares,
            }
            setStateData(state)
            setCompoEdited(true)
            toast(`Successfully duplicated ${geomLength} object(s).`)
        }

        /**
         * event handler to change edition mode from picking editor
         */
        const onToggleMode = useCallback(
            (mode) => {
                DEBUG &&
                    console.log(`Composer: Call onToggleMode for mode ${mode}`)
                if (mode in menuState.viewModes) {
                    // some special case for debug view
                    if (
                        mode === "debug" &&
                        !menuState.viewModes.debug &&
                        !menuState.viewModes.magnet
                    ) {
                        onToggleMode("magnet")
                    }
                    if (
                        mode === "magnet" &&
                        menuState.viewModes.debug &&
                        menuState.viewModes.magnet
                    ) {
                        onToggleMode("debug")
                    }
                    let prevState = {
                        ...menuState,
                    }
                    let menuStates = {
                        ...prevState,
                        viewModes: {
                            ...prevState.viewModes,
                            [mode]: !prevState.viewModes[mode],
                        },
                    }
                    setMenuState(menuStates)
                    updateMenu(menuStates)
                } else if (mode === "3d") {
                    let menuStates = { ...menuState }
                    menuStates.mode3D = !menuStates.mode3D
                    setMenuState(menuStates)
                    updateMenu(menuStates)
                } else if (mode in menuState.editModes) {
                    // edit modes are exclusive: only one can be activated at a time
                    const newEditModes = { ...menuState.editModes }
                    Object.keys(newEditModes).forEach(
                        (k) => (newEditModes[k] = false)
                    )
                    let prevState = {
                        ...menuState,
                    }
                    let newState = !prevState.editModes[mode]
                    let menuStates = {
                        ...prevState,
                        editModes: {
                            ...newEditModes,
                            [mode]: newState,
                        },
                    }
                    setMenuState(menuStates)
                    setCurrentEditMode(newState ? mode : "")
                } else if (mode === "init_edit_modes") {
                    setMenuState((prev) => ({
                        ...prev,
                        editModes: {
                            selection: false,
                            crop: false,
                            point: false,
                            polygon: false,
                            circle: false,
                            circle3p: false,
                            mask: false,
                            square: false,
                        },
                    }))
                    setCurrentEditMode("")
                } else if (mode === "activate_edit_form") {
                    // ceci n'est pas une feature du type toggle.
                    let prevState = {
                        ...menuState,
                    }
                    let menuStates = {
                        ...prevState,
                        viewModes: {
                            ...prevState.viewModes,
                            displayEditForm: true,
                        },
                    }
                    setMenuState(menuStates)
                    updateMenu(menuStates)
                }
            },
            [menuState, updateMenu]
        )

        const onDelete = useCallback(
            async (keys) => {
                let state = {
                    ...stateData,
                }
                setLoader(INCREMENTAL_SAVE)
                const { points, polygons, circles, masks, squares } = state
                if (keys.length > 1) {
                    let validators = 0
                    for (let i = 0; i < keys.length; i++) {
                        DEBUG && console.log(`Remove object ${keys[i]}`)
                        const objToDelete = points.find(
                            (el) => el.id === keys[i]
                        )
                            ? "point"
                            : polygons.find((el) => el.id === keys[i])
                              ? "polygon"
                              : circles.find((el) => el.id === keys[i])
                                ? "circle"
                                : masks.find((el) => el.id === keys[i])
                                  ? "mask"
                                  : squares.find((el) => el.id === keys[i])
                                    ? "square"
                                    : undefined
                        if (objToDelete) {
                            const keyToDel = objToDelete + "s"
                            state = {
                                ...state,
                                [keyToDel]: state[keyToDel].filter(
                                    (el) => el.id !== keys[i]
                                ),
                            }
                            DEBUG && console.log("object to delete", keyToDel)
                            if (INCREMENTAL_SAVE) {
                                await hmComRef.current.deleteObject(
                                    objToDelete,
                                    keys[i]
                                )
                                setLoader(false)
                                DEBUG && console.log("deleted : ", keys[i])
                            }
                            validators++
                        } else {
                            console.warn("uncaught error !")
                        }
                    }
                    if (validators) {
                        DEBUG && console.log(`Deleted ${validators} objects`)
                        setStateData(state)
                        setCompoEdited(true)
                        viewerRef.current?.setCompoEdited(true)
                    }
                } else {
                    const key = keys[0]
                    const [objId, ptId, move] = key.split("#")
                    DEBUG && console.log(`Remove object ${objId}`)
                    const objToDelete = points.find((el) => el.id === objId)
                        ? "point"
                        : polygons.find((el) => el.id === objId)
                          ? "polygon"
                          : circles.find((el) => el.id === objId)
                            ? "circle"
                            : masks.find((el) => el.id === objId)
                              ? "mask"
                              : squares.find((el) => el.id === objId)
                                ? "square"
                                : undefined

                    /** Remove a Hole from a mask */
                    const removeHole = (maskId, holeId) => {
                        const mask = stateData.masks.find(
                            (m) => m.id === maskId
                        )
                        DEBUG && console.log(`removeHole for mask `, mask)
                        let holePos = holeId % 2 ? holeId - 1 : holeId
                        let newHoles = [...mask.holes]
                        DEBUG && console.log(`New Holes before is `, newHoles)
                        newHoles.splice(holePos, 2)
                        DEBUG &&
                            console.log(`New Holes after splice is `, newHoles)
                        let state = {
                            ...stateData,
                            masks: stateData.masks.map((el) =>
                                el.id === maskId
                                    ? { ...el, holes: newHoles }
                                    : el
                            ),
                        }
                        setStateData(state)
                        setCompoEdited(true)
                        return mask
                    }
                    if (
                        objToDelete === "mask" &&
                        !move &&
                        ptId &&
                        ptId.length &&
                        parseInt(ptId) > 1
                    ) {
                        removeHole(objId, parseInt(ptId) - 2)
                        setCompoEdited(true)
                    } else if (
                        objToDelete === "mask" &&
                        move &&
                        parseInt(ptId) > 0
                    ) {
                        removeHole(objId, (parseInt(ptId) - 1) * 2)
                        setCompoEdited(true)
                    } else if (objToDelete) {
                        const keyToDel = objToDelete + "s"
                        setStateData((prevState) => ({
                            ...prevState,
                            [keyToDel]: prevState[keyToDel].filter(
                                (el) => el.id !== objId
                            ),
                        }))
                        DEBUG && console.log("object to delete", keyToDel)
                        if (INCREMENTAL_SAVE) {
                            await hmComRef.current.deleteObject(
                                objToDelete,
                                objId
                            )
                            setLoader(false)
                            DEBUG && console.log("deleted : ", objId)
                        }
                        setCompoEdited(true)
                        viewerRef.current?.setCompoEdited(true)
                    } else {
                        console.warn("uncaught error !")
                    }
                }
            },
            [stateData]
        )

        /**
         * @param {string[]} keys array of object.id
         * @param {any} paylaod payload variable
         * @param {string} type key of the property - ex: label_position
         */
        const updateGeometry = useCallback(
            (keys, payload, type) => {
                if (!type || !keys?.length || !payload) {
                    return
                }
                let state = {
                    ...stateData,
                }
                const { points, polygons, circles, masks, squares } = state
                INCREMENTAL_SAVE && setLoader(INCREMENTAL_SAVE)
                let validators = 0
                for (let i = 0; i < keys.length; i++) {
                    const key = keys[i]
                    DEBUG &&
                        console.log(
                            `ObjectID : ${key} updated; ${type} value set to ${payload}`
                        )
                    const objToUpdate = points.find((el) => el.id === key)
                        ? "point"
                        : polygons.find((el) => el.id === key)
                          ? "polygon"
                          : circles.find((el) => el.id === key)
                            ? "circle"
                            : masks.find((el) => el.id === key)
                              ? "mask"
                              : squares.find((el) => el.id === key)
                                ? "square"
                                : undefined
                    if (objToUpdate) {
                        const keyToUpdate = objToUpdate + "s"
                        state = {
                            ...state,
                            [keyToUpdate]: state[keyToUpdate].map((el) =>
                                el.id === key && el[type]
                                    ? { ...el, [type]: payload }
                                    : el
                            ),
                        }
                        if (INCREMENTAL_SAVE) {
                            setLoader(false)
                        }
                        validators++
                    }
                }
                if (validators > 0) {
                    setStateData(state)
                    setCompoEdited(true)
                    viewerRef.current?.setCompoEdited(true)
                }
            },
            [stateData]
        )

        const updateName = useCallback(
            async (key, newName) => {
                INCREMENTAL_SAVE && setLoader(INCREMENTAL_SAVE)
                DEBUG && console.log(`Rename object ${key} in ${newName}`)
                const { points, polygons, circles, masks, squares } = stateData
                const objToUpdate = points.find((el) => el.id === key)
                    ? "point"
                    : polygons.find((el) => el.id === key)
                      ? "polygon"
                      : circles.find((el) => el.id === key)
                        ? "circle"
                        : masks.find((el) => el.id === key)
                          ? "mask"
                          : squares.find((el) => el.id === key)
                            ? "square"
                            : undefined
                if (objToUpdate) {
                    const keyToUpdate = objToUpdate + "s"
                    setStateData((prevState) => ({
                        ...prevState,
                        [keyToUpdate]: prevState[keyToUpdate].map((el) =>
                            el.id === key ? { ...el, name: newName } : el
                        ),
                    }))
                    if (INCREMENTAL_SAVE) {
                        await hmComRef.current.updateName(
                            objToUpdate,
                            key,
                            newName
                        )
                        setLoader(false)
                    } else {
                        const postUpdateName = (type, key, newName) => {
                            if (type === "circle") {
                                //const circle = this.state.circles.find((el) => el.id === key)
                                //circle.center.name = newName
                                setStateData((prevState) => ({
                                    ...prevState,
                                    circles: prevState.circles.map((el) =>
                                        el.id === key
                                            ? {
                                                  ...el,
                                                  center: {
                                                      ...el.center,
                                                      name: newName,
                                                  },
                                              }
                                            : el
                                    ),
                                }))
                            } else if (type === "polygon") {
                                //const polygon = this.state.polygons.find((el) => el.id === key)
                                //polygon.points.forEach((p) => (p.name = newName))
                                setStateData((prevState) => ({
                                    ...prevState,
                                    polygons: prevState.polygons.map((el) =>
                                        el.id === key
                                            ? {
                                                  ...el,
                                                  points: el.points.map(
                                                      (p) => ({
                                                          ...p,
                                                          name: newName,
                                                      })
                                                  ),
                                              }
                                            : el
                                    ),
                                }))
                            } else if (type === "mask") {
                                //const mask = this.state.masks.find((el) => el.id === key)
                                //mask.points.forEach((p) => (p.name = newName))
                                //mask.holes.forEach((p) => (p.name = newName))
                                setStateData((prevState) => ({
                                    ...prevState,
                                    masks: prevState.masks.map((el) =>
                                        el.id === key
                                            ? {
                                                  ...el,
                                                  points: el.points.map(
                                                      (p) => ({
                                                          ...p,
                                                          name: newName,
                                                      })
                                                  ),
                                                  holes: el.holes.map((p) => ({
                                                      ...p,
                                                      name: newName,
                                                  })),
                                              }
                                            : el
                                    ),
                                }))
                            } else if (type === "square") {
                                setStateData((prevState) => ({
                                    ...prevState,
                                    squares: prevState.squares.map((el) =>
                                        el.id === key
                                            ? {
                                                  ...el,
                                                  points: el.points.map(
                                                      (p) => ({
                                                          ...p,
                                                          name: newName,
                                                      })
                                                  ),
                                              }
                                            : el
                                    ),
                                }))
                            }
                        }
                        postUpdateName(objToUpdate, key, newName)
                    }
                    setCompoEdited(true)
                    viewerRef.current?.setCompoEdited(true)
                }
            },
            [stateData]
        )

        /** This method is called when updating the position of an element on screen during an interactive drag for instance. */

        const updateObject = (key, dx, dy, objectType) => {
            const [objId, ptId, move] = key.split("#")
            const { masks, circles, points, squares, polygons } = stateData
            switch (objectType) {
                case "s":
                    const square = squares.find((el) => el.id === objId)
                    if (square) {
                        let newSquare = { ...square }
                        updateSquarePointDelta(
                            newSquare,
                            parseInt(ptId),
                            dx,
                            dy,
                            move
                        )
                        setStateData((prevState) => ({
                            ...prevState,
                            squares: prevState.squares.map((m) =>
                                m.id === objId ? newSquare : m
                            ),
                        }))
                        if (!compoEdited) {
                            setCompoEdited(true)
                        }
                    }
                    return
                case "m":
                    const mask = masks.find((el) => el.id === objId)
                    if (mask) {
                        let newMask = { ...mask }
                        updateMaskPointDelta(
                            newMask,
                            parseInt(ptId),
                            dx,
                            dy,
                            move
                        )
                        setStateData((prevState) => ({
                            ...prevState,
                            masks: prevState.masks.map((m) =>
                                m.id === objId ? newMask : m
                            ),
                        }))
                        if (!compoEdited) {
                            setCompoEdited(true)
                        }
                    }
                    return
                case "p":
                    const point = points.find((el) => el.id === objId)
                    if (point) {
                        let newPoint = { ...point }
                        updatePointDelta(newPoint, parseInt(ptId), dx, dy, move)
                        setStateData((prevState) => ({
                            ...prevState,
                            points: prevState.points.map((m) =>
                                m.id === objId ? newPoint : m
                            ),
                        }))
                        if (!compoEdited) {
                            setCompoEdited(true)
                        }
                    }
                    return
                case "po":
                    const polygon = polygons.find((el) => el.id === objId)
                    if (polygon) {
                        let newPolygon = { ...polygon }
                        if (move) {
                            updatePolygonData(newPolygon, dx, dy, false)
                        } else {
                            if (
                                newPolygon.points.length > 2 &&
                                (Number(ptId) === 0 ||
                                    Number(ptId) ===
                                        newPolygon.points.length - 1)
                            ) {
                                let delta_x = Math.abs(
                                    newPolygon.points[0].points[0] -
                                        newPolygon.points[
                                            newPolygon.points.length - 1
                                        ].points[0]
                                )
                                let delta_y = Math.abs(
                                    newPolygon.points[0].points[1] -
                                        newPolygon.points[
                                            newPolygon.points.length - 1
                                        ].points[1]
                                )
                                if (delta_x < 1 && delta_y < 1) {
                                    updatePolygonData(newPolygon, dx, dy, true)
                                } else {
                                    updatePointDelta(
                                        newPolygon.points[ptId],
                                        null,
                                        dx,
                                        dy,
                                        true
                                    )
                                }
                            } else {
                                updatePointDelta(
                                    newPolygon.points[ptId],
                                    null,
                                    dx,
                                    dy,
                                    true
                                )
                            }
                        }
                        setStateData((prevState) => ({
                            ...prevState,
                            polygons: prevState.polygons.map((m) =>
                                m.id === objId ? newPolygon : m
                            ),
                        }))
                        if (!compoEdited) {
                            setCompoEdited(true)
                        }
                    }
                    return
                case "c":
                    const circle = circles.find((el) => el.id === objId)
                    if (circle) {
                        let newCircle = { ...circle }
                        updateCircleDelta(
                            newCircle,
                            parseInt(ptId),
                            dx,
                            dy,
                            move
                        )
                        setStateData((prevState) => ({
                            ...prevState,
                            circles: prevState.circles.map((m) =>
                                m.id === objId ? newCircle : m
                            ),
                        }))
                        if (!compoEdited) {
                            setCompoEdited(true)
                        }
                    }
                    return
                default:
            }
        }

        const resetSquareRatio = (key, firstPoint, secondPoint) => {
            // eslint-disable-next-line no-unused-vars
            const [objId, ptId, move] = key.split("#")
            const { masks, squares } = stateData
            const square = squares.find((el) => el.id === objId)
            if (square) {
                let newSquare = { ...square }
                newSquare.points[0].points = firstPoint
                newSquare.points[1].points = secondPoint
                setStateData((prevState) => ({
                    ...prevState,
                    squares: prevState.squares.map((m) =>
                        m.id === objId ? newSquare : m
                    ),
                }))
                setCompoEdited(true)
                return
            }
            const mask = masks.find((el) => el.id === objId)
            if (mask) {
                let newMask = { ...mask }
                newMask.points[0].points = firstPoint
                newMask.points[1].points = secondPoint
                setStateData((prevState) => ({
                    ...prevState,
                    masks: prevState.masks.map((m) =>
                        m.id === objId ? newMask : m
                    ),
                }))
                setCompoEdited(true)
                return
            }
        }

        const setMaskFullSize = (width, height) => {
            const { masks } = stateData
            if (masks.length) {
                DEBUG &&
                    console.log(`Set Mask to full Size of ${width} x ${height}`)
                let newMask = { ...masks[0] }
                newMask.points[0].points[0] = 1
                newMask.points[0].points[1] = 1
                newMask.points[1].points[0] = width - 1
                newMask.points[1].points[1] = height - 1
                setStateData((prevState) => ({
                    ...prevState,
                    masks: prevState.masks.map((m) =>
                        m.id === 0 ? newMask : m
                    ),
                }))
                setCompoEdited(true)
            }
        }

        const updateId = (type, oldId, newId) => {
            DEBUG && console.log(`Change ${type} ID from ${oldId} to ${newId}`)
            setStateData((prevState) => ({
                ...prevState,
                [type]: prevState[type].map((el) =>
                    el.id === oldId ? { ...el, id: newId } : el
                ),
            }))
        }

        /** This method convert a SVG DOM to a file uploaded on file server.<br>
         * @param {Element} svgContent the SVG root node
         * @param {string} nameSuffix a suffix to add to file name. The base file is this.props.picture
         * @param {json} metadata metadata format information to add to file at upload
         * @param {boolean} linkToOriginalFile when the created file is the SVG overlay (geomCompo)
         * @param {boolean} saveAsSvg to save the file in SVG format. By default it's in PNG format.
         * @param {boolean} overwrite true if the uploaded file must overwrite a previous one
         * @param {object} param_picture another picture definition to replace this.props.picture. Usefull when saving at the same time the result of an edited SVG and a geom compo on top of it.
         * @returns {object} if linkToOriginalFile then it returns the id of the created file. If it's a new SVG file then returns the whole new picture object definition as used in MainApp.
         */
        const saveFile = (
            svgContent,
            nameSuffix,
            metadata,
            linkToOriginalFile,
            saveAsSvg,
            param_picture
        ) => {
            var gcId = null
            const convert = saveAsSvg ? buildSVGFile : buildPictureFromSVG
            const extension = saveAsSvg ? ".svg" : ".png"
            return convert(svgContent)
                .then((blob) => {
                    const picName = param_picture?.name ?? picture.src
                    const blobName = picName.substr(0, picName.lastIndexOf("."))
                    blob.name = blobName.endsWith(nameSuffix)
                        ? blobName + extension
                        : blobName + nameSuffix + extension
                    DEBUG &&
                        console.log(
                            `Now upload blob ${blob.name} to server: `,
                            blob
                        )
                    return HMFileIO().uploadFileXHR(blob, dirId, metadata)
                })
                .then((response) => {
                    DEBUG && console.log(`Upload OK: `, response)
                    if (linkToOriginalFile) {
                        gcId = response.id
                        const md = param_picture
                            ? { ...param_picture.metadata, geom_compo_id: gcId }
                            : { ...picture.metadata, geom_compo_id: gcId }
                        return HMFileIO().updateFileMetadata(
                            md,
                            param_picture ? param_picture.id : picture.id
                        )
                    }
                    return response
                })
                .then((uploadedFile) => {
                    if (linkToOriginalFile)
                        return updatePicGeoCompo(
                            param_picture ? param_picture : picture,
                            gcId
                        )
                    else {
                        // add the uploaded picture to file manager picture list
                        return updatePictureState(uploadedFile)
                    }
                })
                .catch(
                    (err) =>
                        DEBUG &&
                        console.log(`Error uploading file to server: ${err}`)
                )
        }

        useImperativeHandle(
            ref,
            () => ({
                cleanUp: async (save) => {
                    onToggleMode("init_edit_modes")
                    if (!save || !compoEdited) {
                        DEBUG && console.log("exit without saving")
                        return "exit without saving"
                    }
                    const newPicture = await viewerRef.current.saveAllData(save)
                    DEBUG &&
                        console.log(
                            "Composer: saving all geocompo data with a newPicture",
                            newPicture
                        )
                    await hmComRef.current.saveGeoCompoData(
                        geomId,
                        stateData.points,
                        stateData.polygons,
                        stateData.masks,
                        stateData.circles,
                        stateData.squares
                    )
                    setCompoEdited(false)
                    if (newPicture?.id)
                        // in case a new file for source data was written
                        await HMCom().duplicateGeoCompo(geomId, newPicture.id)
                    return newPicture
                },
                handleMenu: async (e, action) => {
                    DEBUG &&
                        console.log(`Handle Menu action from Composer `, action)
                    if (viewerRef?.current?.handleMenu) {
                        if (
                            action.type === "copyLink" ||
                            action.type === "copyFile"
                        ) {
                            const newPicture =
                                await viewerRef.current.saveAllData(true)
                            DEBUG &&
                                console.log(
                                    `Composer:handleMenu: Process action with newPicture: `,
                                    newPicture
                                )
                            if (action.type === "copyFile") {
                                // newPicture is set ONLY for modified and saved SVG file
                                if (newPicture) {
                                    DEBUG &&
                                        console.log(
                                            "new picture : ",
                                            newPicture
                                        )
                                } else {
                                    DEBUG && console.log("picture : ", picture)
                                }
                                DEBUG && console.log(newPicture)
                                return copyFile(newPicture ?? picture)
                            } else {
                                // copyLink case
                                const pic = newPicture ? newPicture : picture
                                DEBUG &&
                                    console.log(
                                        `All data Saved and ${action.type} action done`
                                    )
                                return copyLink(pic.src + "#" + pic.id)
                            }
                        } else if (action.type === "3d") {
                            onToggleMode("3d")
                        } else {
                            // sometimes viewerRef.current.handleMenu is undefined when user clicks too fast
                            viewerRef.current.handleMenu(e, action)
                        }
                    }
                },
                pasteGeometry: async (sourceGeometries) => {
                    let svgCanvasWidth, svgCanvasHeight
                    if (viewerRef?.current?.svgCanvas?.width?.baseVal?.value) {
                        svgCanvasWidth =
                            viewerRef?.current?.svgCanvas?.width?.baseVal.value
                    }
                    if (viewerRef?.current?.svgCanvas?.height?.baseVal?.value) {
                        svgCanvasHeight =
                            viewerRef?.current?.svgCanvas?.height?.baseVal.value
                    }
                    if (svgCanvasWidth && svgCanvasHeight) {
                        const hmCom_source = HMCom()
                        await hmCom_source.initGeoCompo(
                            sourceGeometries.source_id
                        )
                        let hmComRes_source =
                            await hmCom_source.loadGeoCompoData()
                        let [
                            pts_source,
                            polygons_source,
                            masks_source,
                            circles_source,
                            squares_source,
                        ] = hmComRes_source
                        let source_geoms = {
                            points: pts_source ?? [],
                            polygons: polygons_source ?? [],
                            masks: masks_source ?? [],
                            circles: circles_source ?? [],
                            squares: squares_source ?? [],
                        }
                        let geomLength = 0
                        const img_source = new Image()
                        img_source.src = sourceGeometries.source_imgsrc
                        img_source.onload = function () {
                            let source_width = this.width
                            let source_height = this.height
                            let deltaRatio_x = svgCanvasWidth / source_width
                            let deltaRatio_y = svgCanvasHeight / source_height
                            if (deltaRatio_x && deltaRatio_y) {
                                for (
                                    let i = 0;
                                    i < source_geoms.points.length;
                                    i++
                                ) {
                                    geomLength++
                                    source_geoms.points[i].id = uuid()
                                    source_geoms.points[i].points[0] *=
                                        deltaRatio_x
                                    source_geoms.points[i].points[1] *=
                                        deltaRatio_y
                                }
                                for (
                                    let i = 0;
                                    i < source_geoms.polygons.length;
                                    i++
                                ) {
                                    geomLength++
                                    source_geoms.polygons[i].id = uuid()
                                    for (
                                        let j = 0;
                                        j <
                                        source_geoms.polygons[i].points.length;
                                        j++
                                    ) {
                                        source_geoms.polygons[i].points[j].id =
                                            uuid()
                                        source_geoms.polygons[i].points[
                                            j
                                        ].points[0] *= deltaRatio_x
                                        source_geoms.polygons[i].points[
                                            j
                                        ].points[1] *= deltaRatio_y
                                    }
                                }
                                for (
                                    let i = 0;
                                    i < source_geoms.masks.length;
                                    i++
                                ) {
                                    geomLength++
                                    source_geoms.masks[i].id = uuid()
                                    for (
                                        let j = 0;
                                        j < source_geoms.masks[i].points.length;
                                        j++
                                    ) {
                                        source_geoms.masks[i].points[j].id =
                                            uuid()
                                        source_geoms.masks[i].points[
                                            j
                                        ].points[0] *= deltaRatio_x
                                        source_geoms.masks[i].points[
                                            j
                                        ].points[1] *= deltaRatio_y
                                    }
                                    for (
                                        let k = 0;
                                        k < source_geoms.masks[i].holes.length;
                                        k++
                                    ) {
                                        source_geoms.masks[i].holes[k].id =
                                            uuid()
                                        source_geoms.masks[i].holes[
                                            k
                                        ].points[0] *= deltaRatio_x
                                        source_geoms.masks[i].holes[
                                            k
                                        ].points[1] *= deltaRatio_y
                                    }
                                }
                                for (
                                    let i = 0;
                                    i < source_geoms.circles.length;
                                    i++
                                ) {
                                    geomLength++
                                    source_geoms.circles[i].id = uuid()
                                    source_geoms.circles[i].normal =
                                        source_geoms.circles[i].normal ?? [
                                            0, 0, 1,
                                        ]
                                    source_geoms.circles[i].center.id = uuid()
                                    source_geoms.circles[i].center.points[0] *=
                                        deltaRatio_x
                                    source_geoms.circles[i].center.points[1] *=
                                        deltaRatio_y
                                    source_geoms.circles[i].radius *=
                                        deltaRatio_x
                                    source_geoms.circles[i].ptOnCircle.id =
                                        uuid()
                                    source_geoms.circles[
                                        i
                                    ].ptOnCircle.points[0] *= deltaRatio_x
                                    source_geoms.circles[
                                        i
                                    ].ptOnCircle.points[1] *= deltaRatio_y
                                }
                                for (
                                    let i = 0;
                                    i < source_geoms.squares.length;
                                    i++
                                ) {
                                    geomLength++
                                    source_geoms.squares[i].id = uuid()
                                    for (
                                        let j = 0;
                                        j <
                                        source_geoms.squares[i].points.length;
                                        j++
                                    ) {
                                        source_geoms.squares[i].points[j].id =
                                            uuid()
                                        source_geoms.squares[i].points[
                                            j
                                        ].points[0] *= deltaRatio_x
                                        source_geoms.squares[i].points[
                                            j
                                        ].points[1] *= deltaRatio_y
                                    }
                                }
                            }
                            const points = [
                                ...stateData.points,
                                ...source_geoms.points,
                            ]
                            const masks = [
                                ...stateData.masks,
                                ...source_geoms.masks,
                            ]
                            const polygons = [
                                ...stateData.polygons,
                                ...source_geoms.polygons,
                            ]
                            const circles = [
                                ...stateData.circles,
                                ...source_geoms.circles,
                            ]
                            const squares = [
                                ...stateData.squares,
                                ...source_geoms.squares,
                            ]
                            let state = {
                                points: points,
                                masks: masks,
                                polygons: polygons,
                                circles: circles,
                                squares: squares,
                            }
                            viewerRef.current?.setCompoEdited(true)
                            setStateData(state)
                            setCompoEdited(true)
                            toast(
                                `Successfully pasted ${geomLength} geometries`
                            )
                        }
                    }
                },
            }),
            [
                compoEdited,
                copyFile,
                copyLink,
                onToggleMode,
                picture,
                stateData,
                geomId,
            ]
        )

        useEffect(() => {
            const initialize = async () => {
                if (!hmComRef.current) return
                const res = await HMFileIO().compose(picture.id)
                if (picture.geomSrc === "TO_COMPUTE") setCompoEdited(true)
                setData(res)
                if (!FORCE_2D_MODE && res && res.depth_uri) {
                    DEBUG &&
                        console.log(
                            `ok received global parameters with DEPTH data, going to 3D mode`
                        )
                    DEBUG && console.log(res)
                    // when working in 3D mode, the geom compo name (id) is the one from the folder containing pictures (because its the same 3D model)
                    setGeomId(dirId)
                    /**
                     * Fetch a file_list.json descriptor from server to get a picture list
                     * then fetch the _global.json descriptor to get phi/theta infos
                     *
                     * `data.global` has a name property
                     */
                    let menuStates = {
                        ...menuState,
                        mode3D: true,
                    }
                    setMenuState(menuStates)
                    updateMenu(menuStates)
                    setLoader(false)
                    setReady(true)
                    return
                }
                DEBUG &&
                    console.log(
                        `will work in 2D mode on file ${picture.id}, picture data is: `,
                        picture
                    )
                // when working in 2D mode, the geom compo name (id) is the one from edited picture
                setGeomId(picture.id)
                // Point of Interest
                updateMenu(menuState)
                await hmComRef.current.initGeoCompo(picture.id)
                const hmComRes = await hmComRef.current.loadGeoCompoData()
                DEBUG && console.log("hmComRes : ", hmComRes)
                const [pts, polygons, masks, circles, squares] = hmComRes
                let state = {
                    points: pts ?? [],
                    polygons: polygons ?? [],
                    masks: masks ?? [],
                    circles: circles ?? [],
                    squares: squares ?? [],
                }
                setStateData(state)
                if (currentEditMode !== "square") onToggleMode("square")
                DEBUG && console.log(`Load GeoCompo Data: `, hmComRes)
                setReady(true)
                setLoader(false)
            }
            initialize().then(() => {
                DEBUG && console.log("initialized")
            })
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [picture])

        useEffect(() => {
            // look into the folder for a *_global.json file, if found and edited file is linked to a 3d model then compose in 3D mode else compose in 2D mode
            DEBUG && console.log(`Folder Id = ${dirId}`)
        }, [dirId])

        useEffect(() => {
          if (stateData.squares.length > 0) {
            removeClassList("menu_composer_track")
          } else {
            addClassList("menu_composer_track")
          }
        }, [stateData.squares])

        useEffect(() => {
          if (compoEdited) {
            removeClassList("menu_composer_save")
            addClassList("menu_composer_copygeom")
            addClassList("menu_composer_copyfile")
          } else {
            addClassList("menu_composer_save")
            removeClassList("menu_composer_copygeom")
            removeClassList("menu_composer_copyfile")
          }
        }, [compoEdited])

        return (
            <ComposerContext.Provider
                value={{
                    stateData: stateData,
                    menuState: menuState,
                    currentEditMode: currentEditMode,
                    newObjects: newObjects, //Controller is listening to this variable in order to check it automatically.
                    setNewObjects: setNewObjects,
                    addPoint: addPoint,
                    addPolygon: addPolygon,
                    addCircle: addCircle,
                    addMask: addMask,
                    addHole: addHole,
                    addSquare: addSquare,
                    updateName: updateName,
                    updateGeometry: updateGeometry,
                    updateObject: updateObject,
                    setMaskFullSize: setMaskFullSize,
                    onDelete: onDelete,
                    onToggleMode: onToggleMode,
                    saveFile: saveFile,
                    unSelectAllEditModes: unSelectAllEditModes,
                    duplicateGeometries: duplicateGeometries,
                }}
            >
                {ready && (
                    <PartView3DFC
                        ref={viewerRef}
                        picture={picture}
                        loader={loader}
                        data={data}
                        force2DCallback={() => {
                            /** this function is called when 3Dmode is not applicable */
                            onToggleMode("3d")
                        }}
                        resetSquareRatio={resetSquareRatio}
                        toLeft={
                            currentIndex > 0
                                ? () => onChangePic("left")
                                : undefined
                        }
                        toRight={
                            currentIndex < picturesLength - 1
                                ? () => onChangePic("right")
                                : undefined
                        }
                        onTrack={
                            currentIndex > 0 && stateData.squares.length > 0
                                ? () => handleMenu(undefined, {
                                    type: "track",
                                    payload: picture,
                                })
                                : undefined
                        }
                        leftCount={currentIndex}
                        rightCount={picturesLength - currentIndex - 1}
                    />
                )}
            </ComposerContext.Provider>
        )
    }
)

export default ComposerFC
